import React, { Fragment, ReactNode } from "react";

import { Collapse } from "antd";
import { keys, propOr } from "ramda";

import { ArrowSVG } from "assets/icons";

import { Alert } from "./Alert";

import styles from "./Alert.module.scss";

type Props = {
  rejectionReasons: Record<string, string>;
  onClose: () => void;
  header?: ReactNode;
  description?: ReactNode;
};

const errorLabelMap: Record<string, string> = {
  basic_organization_information: "Basic organization information",
  organization_contact_details: "Organization contact details",
  organization_representative: "Organization representative",
  other_reason: "Other reason",
  contact_details: "Address details",
  certification_documents: "Certification documents",
  self_certification_questionnaire: "Self Certification Questionnaire",
  identity: "Identity",
  virtual_assets_info: "Virtual Assets Info",
  proof_of_address_documents: "Proof Of Address Documents",
};

const customExpandIcon = ({ isActive }: { isActive?: boolean }) => (
  <ArrowSVG style={{ transform: isActive ? "rotate(-180deg)" : undefined }} />
);

export const RejectionAlert = ({
  rejectionReasons,
  onClose,
  header = "Submission Rejected",
  description = "Rejection description, reasons explanation...",
}: Props) => {
  const rejectionKeys = keys(rejectionReasons);

  return (
    <Alert
      type='error'
      message={header}
      description={
        <>
          <p>{description}</p>
          <Collapse
            defaultActiveKey={[]}
            className={styles.rejectionCollapsable}
            expandIcon={customExpandIcon}
            expandIconPosition='end'
          >
            {rejectionKeys.map((key) => (
              <Collapse.Panel
                key={key}
                header={propOr(key, key, errorLabelMap)}
                className={styles.rejectionCollapsable_panel}
              >
                {rejectionReasons[key].split("\n\n").map((paragraph, index) => (
                  <p key={index}>
                    {paragraph.split("\n").map((line, lineIndex) => (
                      <Fragment key={lineIndex}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                  </p>
                ))}
              </Collapse.Panel>
            ))}
          </Collapse>
        </>
      }
      closable={false}
      onClose={onClose}
    />
  );
};
